<template>
  <div>
    <b-dropdown
      v-model="selectedLabel"
      aria-role="list"
      class="mb-3"
    >
      <template #trigger>
          <b-button
              :label="selectedLabel.text"
              type="is-primary"
              icon-right="menu-down" />
      </template>
      <b-dropdown-item
          v-for="(menu, index) in dropdownOptions"
          :key="index"
          :value="menu" aria-role="listitem"
          >
          <div class="media">
              <b-icon class="media-left" :icon="menu.icon"></b-icon>
              <div class="media-content">
                  <h3>{{menu.text}}</h3>
              </div>
          </div>
      </b-dropdown-item>
    </b-dropdown>

    <div class="content" v-if="selectedLabel.value != ''">
      <span class="py-3 is-inline-block">{{ selectedLabel.text }}</span>
      <b-field>
        <b-datepicker
          v-model="selected"
          :date-formatter="dateFormatter"
          ref="datepicker"
          expanded
          placeholder=" am ">
        </b-datepicker>

        <b-button
          v-model="startDate"
          @click="$refs.datepicker.toggle()"
          icon-left="calendar-today"
          type="is-primary" />
      </b-field>
      <b-input type="hidden" readonly :value="dateSelected" />
    </div>
  </div>
</template>

<script>
import FormTemplateMixin from '@/mixins/FormTemplateMixin'
import moment from 'moment'

export default {
  name: 'app-dropdown-job',
  mixins: [FormTemplateMixin],
  props: {
    data: {
      default: null
    }
  },
  data () {
    return {
      selectedLabel: null,
      startDate: new Date(),
      selected: null
    }
  },
  computed: {
    dateSelected() {
      if (this.selected) {
        let value = {
          date: this.selected,
          status: this.selectedLabel.value
        }
        this.$emit('onSelected', value)
      }
    },
  },

  methods: {
    dateFormatter(date) {
      return moment(date).format("D.M.YYYY")
    }
  },

  created () {
    this.selectedLabel = this.data.selectedOption
    this.dropdownOptions = this.data.dropdownOptions
  }
}
</script>

<template>
  <div>
    <section class="section hero is-fullheight is-error-section">
      <hero-bar>
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-lock default"></i></span>
          <span
            >{{ $getTranslation("form.accept") }}
            {{ $getTranslation("general.views.invitation", 1) }}</span
          >
        </p>
      </hero-bar>
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="card has-card-header-background">
              <div  class="column">
                <img src="/img/project-logo-wide_1280px.png" height="71" width="640" alt="Project header"/>
              </div>
              <div class="column is-three-fifths">
                <h3 class="is-3 title">{{ projectTitle() }}</h3>
              </div>
              <div
                class="column is-three-fifths"
                v-html="projectDescription()"
              ></div>
              <div class="column is-three-fifths" v-if="item && item.owner">
                {{ $getTranslation('invitation.more-information') }} <a :href="`mailto:${item.owner.email}`">{{ item.owner.name }}</a>
              </div>
            </div>
            <div class="column is-two-fifths">
              <div class="card has-card-header-background">
                <b-notification
                  v-if="error"
                  has-text-centered
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-html="getErrors()"
                >
                </b-notification>

                <div class="card-content">
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <form
                      ref="form"
                      method="POST"
                      action="/accept-invitation"
                      @submit.prevent="handleSubmit"
                    >
                      <!-- <app-dropdown-job
                        :data="{selectedOption:selectedOption, dropdownOptions:jobOptions}"
                        @onSelected="onSelected"
                      /> -->

                      <div v-if="!isLoggedIn">
                        <InputWithValidation
                          v-if="media !== 'app'"
                          horizontal
                          :rules="nameRules()"
                          type="name"
                          v-model="form.name"
                          icon="account"
                          :label="$getTranslation('auth.form.name.label')"
                          :placeholder="
                            $getTranslation('auth.form.name.placeholder')
                          "
                        />

                        <InputWithValidation
                          horizontal
                          :rules="emailRules"
                          type="email"
                          v-model="form.email"
                          icon="email"
                          :label="$getTranslation('auth.form.email.label')"
                          :placeholder="
                            $getTranslation('auth.form.email.placeholder')
                          "
                        />
                      </div>
                      <div v-else>
                        <!-- provide option to accept project when participant
                        account is already created and participant is
                        logged in -->
                      </div>

                      <InputWithValidation
                        horizontal
                        :rules="passwordRules()"
                        vid="password"
                        type="password"
                        v-model="form.password"
                        icon="lock"
                        :label="$getTranslation('auth.form.password.label')"
                        :placeholder="
                          $getTranslation('auth.form.password.placeholder')
                        "
                        password-reveal
                      />

                      <InputWithValidation
                        horizontal
                        :rules="confirmRules()"
                        type="password"
                        v-model="form.password_confirmation"
                        icon="lock"
                        :label="
                          $getTranslation('auth.form.passwordConfirm.label')
                        "
                        :placeholder="
                          $getTranslation(
                            'auth.form.passwordConfirm.placeholder'
                          )
                        "
                        password-reveal
                      />

                      <hr />
                      <div class="field is-grouped">
                        <div class="control">
                          <b-button
                            native-type="submit"
                            type="is-black"
                            @click="passes(submit('accept'))"
                          >
                            <span>{{
                              $getTranslation("form.accept")
                            }}</span>
                          </b-button>
                        </div>
                        <div class="control">
                          <b-button
                            type="is-black is-outlined"
                            @click="reset"
                            >{{ $getTranslation("form.reset") }}</b-button
                          >
                        </div>
                        <div class="control">
                          <b-button
                            type="is-black is-outlined"
                            @click="passes(submit('reject'))"
                            >{{ $getTranslation("form.reject") }}</b-button
                          >
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ApiAcceptInvite, ApiRejectInvite, ApiProjectDetails } from '@/api-routes.js'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'
import Dropdown from './JobDropdown'

export default {
  name: 'app-reset-password',
  mixins: [FormTemplateMixin],
  components: {
    'app-dropdown-job': Dropdown
  },
  data () {
    return {
      id: 0,
      token: null,
      media: null,
      action: null,
      // isHidden: false,
      formObj: {
        name: null,
        email: null,
        accepted: 0,
        // old_password: null,
        password: null,
        password_confirmation: null,
        media: null,
        /*job_start: null,
        job_status: null,
        profile: {
          job_start: null,
          job_status: null
        }*/
      },
    }
  },
  computed: {
    ...mapGetters([
      "item",
      "error"
    ]),
    selectedOption () {
      return {value: 0, text: this.$getTranslation('profile.job-options')[0]["0"]}
    },

    jobOptions() {
      return [
        { value: 'Y', text: this.$getTranslation('profile.job-options')[1]["Y"] },
        { value: 'N', text: this.$getTranslation('profile.job-options')[2]["N"] },
        { value: 'M', text: this.$getTranslation('profile.job-options')[3]["M"] }
      ]
    },
    emailRules () {
      return 'required|email'
    },
  },

  methods: {
    ...mapActions([
      "setPasswords",
      "signUserOut",
      "fetchItem",
      "setError",
      "clearError"
    ]),

    confirmRules () {
      if (this.form.accepted) {
        return 'required|confirmed:password'
      }
    },

    passwordRules () {
      if (this.form.accepted) {
        return 'required'
      }
    },

    nameRules () {
      if (this.form.accepted && this.form.media !== 'app') {
        return 'required'
      }
    },

    // not in use
    /*onSelected(value) {
      this.form.profile.job_status = value.status
      this.form.job_status = value.status
      // this.form.profile.job_start = value.date
      this.datepickerConvert(value.date)
      this.clearError()
      // console.log(this.form, value.date)
    },*/

    // TODO CLEANUP
    /*dateConvert(date) {
      // console.log('date: ', date)
      // TODO create helper function for parsing date !!!

        // let converted = date.getFullYear()+'-'+ String(date.getMonth()+1).padStart(2, '0') +'-'+ String(date.getDate()).padStart(2, '0');
        this.form.profile.job_start = date

    },*/

    // not in use  here
    /*datepickerConvert(date) {
      if (!date) {
        return 'U'
      }
      // let converted = moment(date, 'YYYY-MM-DD H:I:S').format('M/D/YYYY')
      // console.log(converted)
      this.form.profile.job_start = moment(date).format("D.M.YYYY")
      this.form.job_start = moment(date).format("D.M.YYYY")
      // return converted
    },*/

    submit (value) {
      this.form.token = this.token;
      let endpoint = ApiAcceptInvite
      this.action = 'accepted'
      this.form.media = this.media

      if (typeof value === 'undefined' || value ==='accept') {
        this.form.accepted = 1
        /*if (this.form.job_start === null) {
          // this.$toast.indefinite('IS REQUIRED')
          this.setError(this.$getTranslation('invitation.job-required'))
          return false
        }*/
      } else {
        this.form.accepted = 0
        this.action = 'rejected'
        endpoint = ApiRejectInvite
      }

      let payload = {
        'endpoint': endpoint,
        'data': this.form
      }

      let message = this.$getTranslation('invitation.accepted')
      // console.log('payload', payload)
      this.setPasswords(payload)
        .then(result => {
          console.log('error: ', this.error, result)
          if (this.error === null) {
            console.log('errr null!!')
            message = this.$getTranslation('invitation.accepted')
            this.$toast.success(message)
          } else {
            message = this.$getTranslation('invitation.rejected')
            this.$toast.danger(message)
          }
          // TEST - comment out
          this.$router.push('/');
        })
        .catch(error => {
          console.log("catch error form:", error);
          this.clearError();
          message = this.$getTranslation('invitation.rejected')
          this.$toast.danger(message)
        });

    },

    projectDescription () {
      if (this.item !== null) {
        // console.log('description? ', this.item)

        return this.item.description
      }
    },
    projectTitle () {
      if (this.item !== null) {
        // console.log('description? ', this.item)
        return this.item.title
      }
    },
  },

  beforeMount() {
    if(this.$route.meta.type === 'invitation' && this.isLoggedIn) {
      this.signUserOut();
    }
  },
  created () {

    // console.log('WITHOUT TOKEN NOT CAUGHT !!!', this.$route)
    // TODO - accpet invitation if you have participant account !!!
    this.media = this.$route.params.media
    this.token = this.$route.params.token
    if (typeof this.token === 'undefined') {
      console.log('undefined token, invalid - end process!!!')
      let message = this.$getTranslation('auth.missing-token')
      this.$toast.danger(message)
    }

    if (typeof this.$route.params.media === 'undefined') {
      this.media = 'app'
    }

    // Fetch item if token is ok
    let payload = {
      endpoint: ApiProjectDetails + this.$route.params.token
    }

    this.fetchItem(payload)
    // this.jobOptions = this.$getTranslation('profile.job-options')
    // console.log(this.$getTranslation('profile.job-options'))
    /*let arr = [];
    Object.values(this.$getTranslation('profile.job-options')).forEach((item) => {
      // console.log(item)
        for (const [key, value] of Object.entries(item)) {
          console.log(`${key}: ${value}`);
        // items[key] = value
        }
    })*/
  }
}
</script>
